import React, { useState } from "react";
import { Modal, Box, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import styles from "./YogaModule.module.css";
import AddVideoModal from "./AddVideoModal";
import EditVideoModal from "./EditVideoModal";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { toastMessage } from "../../utils/toastMessage";
import { patch, post } from "../../config/axios";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";

const VideoModal = ({
  viewModal,
  setViewModal,
  videoData,
  setViewData,
  category,
  fetchUsers,
}) => {
  const [addVideoModalOpen, setAddVideoModalOpen] = useState(false);
  const [editVideoModalOpen, setEditVideoModalOpen] = useState(false);
  const [editVideoData, setEditVideoData] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState(null);

  const handleDelete = (row) => {
    setDeleteModalOpen(true); 
    setDeleteVideo(row); 
  };
  
  const confirmDelete = async () => {
    try {
      await patch(`/api/dashboard/yogaFeatures/deleteYogaVideo/${deleteVideo._id}/${category?.categoryId}`);
      toastMessage("Video deleted successfully!", "success");
      fetchUsers(""); 
      setDeleteModalOpen(false); 
      window.location.reload();
    } catch (err) {
      console.error("Error deleting video:", err);
      toastMessage("Failed to delete the video.", "error");
    }
  };
  

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank", "noopener, noreferrer"); 
  };

  const videoTableColumns = [
    { id: "S.No", label: "S. No" },
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    { id: "thumbnail", label: "Thumbnail", type: "IMAGE", onClick: handleImageClick },
    { id: "videoUrl", label: "Video", type: "VIDEO" },
    { id: "coverImage", label: "Cover Image", type: "IMAGE", onClick: handleImageClick },
    { id: "caloriesBurn", label: "Calories Burn" },
    { id: "benefits", label: "Benefits" },
    {
      id: "createdAt",
         label: "Publishing Date",
         minWidth: 70,
         align: "center",
       },
    {
      id: "action",
      label: "Actions",
      type: "ACTION",
      actions: ["edit", "delete"],
    },
  ];

  const transformedVideos = Array.isArray(videoData?.videos)
  ? videoData.videos.map((video, index) => ({
      ...video,
      "S.No": index + 1,
      // createdAt: video.createdAt ? moment.utc(video.createdAt).format("DD-MM-YYYY") : "-", 
      action: { edit: true, delete: true }, 
    }))
  : [];


  const handleEdit = (row) => {
    setEditVideoData(row); 
    setEditVideoModalOpen(true);
  };

  return (
    <div>
      <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
          setViewData({});
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className={styles.view_modal}
          sx={{
            width: "90%",
            maxWidth: "1200px",
            height: "80%",
            maxHeight: "900px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            overflowY: "auto",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => {
              setViewModal(false);
              setViewData({});
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              marginTop: "25px",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {category?.categoryName}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#1565c0" },
              }}
              onClick={() => setAddVideoModalOpen(true)}
            >
              Add Video
            </Button>
          </Box>

          <CustomTable
            data={transformedVideos}
            columns={videoTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            loading={false}
          />

          <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#1976d2",
              color: "white",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
            onClick={() => {
              setViewModal(false);
              setViewData({});
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      <AddVideoModal
        open={addVideoModalOpen}
        onClose={() => setAddVideoModalOpen(false)}
        categoryId={category?.categoryId}
        onSuccess={() => {
          toastMessage("Video added successfully!", "success");
          fetchUsers("");
          setAddVideoModalOpen(false);
          setViewModal(false);
        }}
      />

      <EditVideoModal
        open={editVideoModalOpen}
        onClose={() => setEditVideoModalOpen(false)}
        videoData={editVideoData}
        onSuccess={() => {
          toastMessage("Video updated successfully!", "success");
          fetchUsers("");
          setEditVideoModalOpen(false);
          setViewModal(false);
        }}
      />

    <DeleteModal
      open={isDeleteModalOpen}
      onClose={() => setDeleteModalOpen(false)}
      onDelete={confirmDelete}
      data={deleteVideo}
    />

    </div>
  );
};

export default VideoModal;
