import React, { useState, useEffect } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get } from "../../config/axios";
import { Typography } from "@mui/material";
import { useDebouncedValue } from "../../helper/debounce";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import moment from "moment";

const Feedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const debouncedSearch = useDebouncedValue(search, 500);
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const fetchFeedbacks = async (searchValue) => {
        try {
            setLoading(true);
            const res = await get(
                `/api/dashboard/appFeatures/getFeedback?page=${page}&limit=${10}&search=${searchValue}`
            );
            setFeedbacks(
                res?.data.map((item) => ({
                    ...item,
                    fullname: item.user ? item.user?.fullname : "",
                    phone: item.user ? item.user?.phone : "",
                    action: { edit: false, delete: true },
                }))
            );
            setLoading(false);
            setPageCount(res?.totalPage);
            setTotalData(res?.totalData);
            setPerPage(res?.perPage);
            setCurrentPage(res?.currentPage);
        } catch (err) {
            console.error("Error:", err);
            setLoading(true);
        }
    };
    useEffect(() => {
        if (search === "") {
            fetchFeedbacks("");
        } else if (debouncedSearch) {
            fetchFeedbacks(debouncedSearch);
        }
    }, [search, debouncedSearch, message, page]);

    const handleSearch = (searchText) => {
        setSearch(searchText.trim());
        setPage(1);
    };
    const handleChange = (page) => {
        setPage(page);
    };

    const feedbackTableColumns = [
        {
            id: "S.No",
            label: "S.No",
            minWidth: 70,
            align: "left",
            format: (value, index) => index + 1,
        },
        {
            id: "user.fullname",
            label: "Full Name",
            minWidth: 70,
            align: "left",
        },
        {
            id: "user.phone",
            label: "Phone No.",
            minWidth: 70,
            align: "left",
        },
        {
            id: "concerns",
            label: "Concerns",
            minWidth: 150,
            align: "left",
        },
        {
            id: "description",
            label: "Description",
            minWidth: 200,
            align: "left",
        },
        {
            id: "createdAt",
            label: "Created At",
            minWidth: 150,
            align: "left",
            format: (value) => moment(value).format("DD-MM-YYYY HH:mm:ss"),
        },

    ];

    return (
        <Layout>
            <div style={{ padding: "1rem" }}>
                <Typography variant="h5">Feedbacks</Typography>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <Searchbar
                            search={handleSearch}
                            placeholder={"Search by concern"}
                            debounceTime={1000}
                        />
                    </div>
                </div>
                <CustomTable
                    data={feedbacks}
                    columns={feedbackTableColumns}
                    handlePageChange={(page) => handleChange(page)}
                    loading={loading}
                    pageCount={pageCount}
                    pageNumber={page}
                    totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
                />
            </div>
        </Layout>
    );
};

export default Feedback;
