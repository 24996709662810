// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import Layout from "../../layout/Main/Layout";
// import CustomTable from "../../components/Custom/Table/CustomTable";
// import { get, put, post, patch } from "../../config/axios";
// import { Button, Typography } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import Searchbar from "../../components/Custom/SearchBar/Searchbar";
// import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
// import { deleteAPI } from "../../helper/apiCallHelper";
// import { userTableColumns } from "../../constants/userPage";
// import { useDebouncedValue } from "../../helper/debounce";
// import { toastMessage } from "../../utils/toastMessage";
// //import FormModal from "../../components/Custom/FormModal/FormModal";
// import PatientModal from "./PatientModal";
// import { userFormFields } from "../../constants/userPage";
// import { useNavigate } from "react-router-dom";
// import { utils, writeFile } from "xlsx";
// import moment from "moment";

// const Users = () => {
//   const [users, setUsers] = useState([]);
//   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deleteUser, setDeleteUser] = useState("");
//   const [search, setSearch] = useState("");
//   const [message, setMessage] = useState("");
//   const [page, setPage] = useState(1);
//   const [pageCount, setPageCount] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const debouncedSearch = useDebouncedValue(search, 500);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [editModal, setEditModal] = useState(false);
//   const [editData, setEditData] = useState({});
//   const navigate = useNavigate();

//   const fetchUsers = async (searchValue) => {
//     setLoading(true);
//     await get(
//       `/api/dashboard/dashUser/getAllAppUsers?page=${page}&limit=${10}&search=${searchValue}&userType=PATIENT`
//     )
//       .then((res) => {
//         console.log(res);
//         setUsers(
//           res?.data.map((item) => ({
//             ...item,
//             action: { edit: true, delete: true },
//           }))
//         );
//         setLoading(false);
//         setPageCount(res?.totalPage);
//       })
//       .catch((err) => {
//         console.log("err", err);
//         setLoading(true);
//       });
//   };

//   useEffect(() => {
//     if (search === "") {
//       fetchUsers("");
//     } else if (debouncedSearch) {
//       fetchUsers(debouncedSearch);
//     }
//   }, [search, debouncedSearch, message, page]);

//   // const handleEdit = (row) => {
//   //   // Implement the edit action for the selected row
//   //   console.log("========",row);
//   //   openModal("edit", row);
//   // };

//   const handleEdit = (row) => {
//     // Normalize gender value before opening edit modal
//     const normalizedRow = {
//       ...row,
//       gender: normalizeGender(row.gender),
//     };
//     //console.log("Normalized Row:", normalizedRow);
//     openModal("edit", normalizedRow);
//   };

//   const normalizeGender = (gender) => {
//     if (!gender) return ""; // Handle undefined or null case if necessary
//     switch (gender.toUpperCase()) {
//       case "MALE":
//         return "Male";
//       case "FEMALE":
//         return "Female";
//       case "OTHER":
//         return "Other";
//       default:
//         return gender; // Return original value if not found in cases
//     }
//   };

//   const handleDelete = (row) => {
//     openModal("delete", row);
//   };

//   const handleDeleteUser = async (row) => {
//     try {
//       const deletedUser = await patch(
//         `/api/dashboard/dashUser/deleteUser/${row._id}`
//       );
//       setMessage(deletedUser?.message);
//       toastMessage(deletedUser?.message, "success");
//     } catch (error) {
//       console.log("error", error);
//       setMessage("Something went wrong!");
//       toastMessage("Something went wrong!", "error");
//     }
//     setDeleteModalOpen(false);
//   };

//   const handleStatus = (row) => {
//     // Implement the status chnage for the selected row
//     console.log("Delete clicked for row34:", row);
//   };

//   // const handleActive = async (id, active) => {
//   //   setLoading(true);
//   //   let response = await put(
//   //     `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
//   //     {
//   //       active: active,
//   //     }
//   //   );
//   //   setLoading(false);
//   //   setMessage(response.message);
//   //   toastMessage(response.message, "success");
//   // };

//   const handleActive = async (id, active) => {
//     setLoading(true);
//     let response = await put(
//       `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
//       {
//         active: active,
//       }
//     );
//     setLoading(false);

//     const username = response.data.fullname;
//     const message = active
//       ? `${username} has been successfully activated.`
//       : `${username} has been successfully deactivated.`;

//     setMessage(message);
//     toastMessage(message, "success");
//   };

//   const handleSearch = (searchText) => {
//    setSearch(searchText.trim());
//     setPage(1);
//   };

//   const handleCloseDeleteModal = () => {
//     setDeleteModalOpen(false);
//   };

//   const handleChange = (page) => {
//     setPage(page);
//   };

//   const openModal = (type, dataForEdit) => {
//     if (type === "add") {
//       setIsModalOpen(true);
//     } else if (type === "edit") {
//       setEditModal(true);
//       setEditData(dataForEdit);
//     } else if (type === "delete") {
//       setDeleteModalOpen(true);
//       setDeleteUser(dataForEdit);
//     }
//   };

//   const closeModal = (type) => {
//     if (type === "add") {
//       setIsModalOpen(false);
//     } else if (type === "edit") {
//       setEditModal(false);
//       setEditData({});
//     }
//   };

//   const handleSubmit = async (formData, isEditing, id) => {
//     setLoading(true);
//     try {
//        // Transform gender value back to uppercase before submitting
//     if (formData.gender) {
//       switch (formData.gender.toUpperCase()) {
//         case "MALE":
//           formData.gender = "MALE";
//           break;
//         case "FEMALE":
//           formData.gender = "FEMALE";
//           break;
//         case "OTHER":
//           formData.gender = "OTHER";
//           break;
//         default:
//           break;
//       }
//     }
//       if (isEditing) {
//         const { ...data } = formData;
//         let response = await put(
//           `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
//           data
//         );
//         setMessage(response.message);
//         if (search) {
//           fetchUsers(search);
//         } else {
//           fetchUsers("");
//         }
//         toastMessage(response.message, "success");
//       } else {
//         formData = {
//           ...formData,
//           userType: "PATIENT",
//         };
//         const { ...data } = formData;
//         await post("/api/dashboard/dashUser/addAccount", { data });
//         setMessage("Successfully added");
//         setIsModalOpen(false);
//       }
//     } catch (err) {
//       console.error("Error:", err);
//       setMessage("Error while processing the request");
//       toastMessage("Error while updating", "error");
//     }
//     setLoading(false);
//   };

//   const handleDisplay = async (row) => {
//     console.log(row);

//     try {
//       const data = await get(
//         `/api/dashboard/dashUser/getExcelDatailByPatient/${row?._id}`
//       );

//       console.log(data);

//       const header1 = [
//         "Name",
//         "Gender",
//         "State",
//         "Phone",
//         "Date_of_Birth",
//         "Language_Prefrence",
//         "Created_At",
//         "City",
//       ];

//       const sheet1 = [
//         {
//           Name: row?.fullname,
//           Gender: row?.gender,
//           State: row?.state,
//           Phone: row?.countryCode + row?.phone,
//           Date_of_Birth: moment(row?.dob).format("DD-MM-YYYY"),
//           Language_Prefrence: row?.languagePreference,
//           Created_At: moment(row.createdAt).format("DD-MM-YYYY"),
//           City: row?.city,
//         },
//       ];

//       const headerDiary = [
//         "Category",
//         "Question",
//         "Answer",
//         "Scores",
//         "Created_At",
//         "Date"
//       ];

//       const diarySheet = [];

//       data?.diary.map((d) => {
//         d.responses.map((response) => {
//           diarySheet.push({
//             Category: d.category,
//             Question: response.question,
//             Answer: response.answer,
//             Scores: response.scores,
//             Created_At: moment(d.createdAt).format("DD-MM-YYYY"),
//             Date:moment(d.date).format("DD-MM-YYYY"),
//           });
//         });
//       });

//       const headerIbs = ["Question", "Answer", "Created_At","Date"];

//       const ibsData = [];

//       data?.ibsAssessmentScore?.map((ibs) => {
//         ibs?.assessment?.map((ib) => {
//           ibsData.push({
//             Question:
//               ib?.answeredQuestions?.questionDetails.questionDescription,
//             Answer: ib?.answeredQuestions?.answer.map((a) => a).join(","),
//             Created_At: moment(ibs.createdAt).format("DD-MM-YYYY"),
//             Date:moment(ibs.date).format("DD-MM-YYYY"),
//           });
//         });
//       });

//       const wb = utils.book_new();
//       const ws1 = utils.json_to_sheet(sheet1, { header: header1 });
//       utils.book_append_sheet(wb, ws1, "Patient Details");
//       const ws2 = utils.json_to_sheet(diarySheet, { header: headerDiary });
//       utils.book_append_sheet(wb, ws2, "Diary");
//       const ws3 = utils.json_to_sheet(ibsData, { header: headerIbs });
//       utils.book_append_sheet(wb, ws3, "IBS Assessment");

//       const fileName = `${row?.fullname.replace(/\s+/g, '_')}_Details.xlsx`;
//       writeFile(wb, fileName);
//     } catch (error) {
//       console.log(error);
//     }

//     // Implement the edit action for the selected row
//     // navigate("/stats");
//   };

//   return (
//     <>
//       <Layout>
//         <div style={{ padding: "1rem" }}>
//           <Typography variant="h5">Patient's Detail</Typography>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <div style={{ width: "40%" }}>
//               <Searchbar
//                 search={handleSearch}
//                 placeholder={"Search by full name or phone number "}
//                 debounceTime={1000}
//               />
//             </div>

//             {/* <Button
//               onClick={() => openModal("add")}
//               variant="outlined"
//               startIcon={<AddIcon fontSize="large" />}
//               style={{ fontWeight: "bold" }}
//             >
//               add patient
//             </Button> */}
//           </div>
//           <CustomTable
//             data={users}
//             columns={userTableColumns}
//             handleEdit={handleEdit}
//             handleDelete={handleDelete}
//             handleStatus={handleStatus}
//             handleDisplay={handleDisplay}
//             handleActive={(row, active) => handleActive(row, active)}
//             handlePageChange={(page) => handleChange(page)}
//             pageNumber={page}
//             pageCount={pageCount}
//             loading={loading}
//           />
//         </div>
//       </Layout>
//       <DeleteModal
//         open={isDeleteModalOpen}
//         onClose={handleCloseDeleteModal}
//         onDelete={handleDeleteUser}
//         data={deleteUser}
//       />
//          {/* common modal for patient and doctor */}
//       <PatientModal
//         isOpen={isModalOpen || editModal}
//         onClose={() => closeModal(editModal ? "edit" : "add")}
//         onSubmit={handleSubmit}
//         fields={userFormFields}
//         header={editModal ? "Edit Patient" : "Add Patient"}
//         initialData={editData}
//         isEditing={editModal}
//       />
//     </>
//   );
// };

// export default Users;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, patch } from "../../config/axios";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { userTableColumns } from "../../constants/userPage";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
//import FormModal from "../../components/Custom/FormModal/FormModal";
import PatientModal from "./PatientModal";
import { userFormFields } from "../../constants/userPage";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import moment from "moment";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();

  const fetchUsers = async (searchValue) => {
    setLoading(true);
    await get(
      `/api/dashboard/dashUser/getAllAppUsers?page=${page}&limit=${10}&search=${searchValue}&userType=PATIENT`
    )
      .then((res) => {
        console.log(res);
        setUsers(
          res?.data.map((item) => ({
            ...item,
            action: { edit: true, delete: true },
          }))
        );
        setLoading(false);
        setPageCount(res?.totalPage);
        setTotalData(res?.totalData);
        setPerPage(res?.perPage);
        setCurrentPage(res?.currentPage);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  // const handleEdit = (row) => {
  //   // Implement the edit action for the selected row
  //   console.log("========",row);
  //   openModal("edit", row);
  // };

  const handleEdit = (row) => {
    // Normalize gender value before opening edit modal
    const normalizedRow = {
      ...row,
      gender: normalizeGender(row.gender),
    };
    //console.log("Normalized Row:", normalizedRow);
    openModal("edit", normalizedRow);
  };

  const normalizeGender = (gender) => {
    if (!gender) return ""; // Handle undefined or null case if necessary
    switch (gender.toUpperCase()) {
      case "MALE":
        return "Male";
      case "FEMALE":
        return "Female";
      case "OTHER":
        return "Other";
      default:
        return gender; // Return original value if not found in cases
    }
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedUser = await patch(
        `/api/dashboard/dashUser/deleteUser/${row._id}`
      );
      setMessage(deletedUser?.message);
      toastMessage(deletedUser?.message, "success");
    } catch (error) {
      console.log("error", error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  // const handleActive = async (id, active) => {
  //   setLoading(true);
  //   let response = await put(
  //     `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
  //     {
  //       active: active,
  //     }
  //   );
  //   setLoading(false);
  //   setMessage(response.message);
  //   toastMessage(response.message, "success");
  // };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
      {
        active: active,
      }
    );
    setLoading(false);

    const username = response.data.fullname;
    const message = active
      ? `${username} has been successfully activated.`
      : `${username} has been successfully deactivated.`;

    setMessage(message);
    toastMessage(message, "success");
  };

  const handleSearch = (searchText) => {
    setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = async (formData, isEditing, id) => {
    setLoading(true);
    try {
      // Transform gender value back to uppercase before submitting
      if (formData.gender) {
        switch (formData.gender.toUpperCase()) {
          case "MALE":
            formData.gender = "MALE";
            break;
          case "FEMALE":
            formData.gender = "FEMALE";
            break;
          case "OTHER":
            formData.gender = "OTHER";
            break;
          default:
            break;
        }
      }
      if (isEditing) {
        const { ...data } = formData;
        let response = await put(
          `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
          data
        );
        // Extracting the username from the response
        const username = response.data.fullname;

        // Setting the success message
        const message = `${username} has been updated.`;
        setMessage(message);
        if (search) {
          fetchUsers(search);
        } else {
          fetchUsers("");
        }
        toastMessage(message, "success");
      } else {
        formData = {
          ...formData,
          userType: "PATIENT",
        };
        const { ...data } = formData;
        await post("/api/dashboard/dashUser/addAccount", { data });
        setMessage("Successfully added");
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };

  // const handleDisplay = async (row) => {
  //   console.log(row);

  //   try {
  //     const data = await get(
  //       `/api/dashboard/dashUser/getExcelDatailByPatient/${row?._id}`
  //     );

  //     console.log(data);

  //     const header1 = [
  //       "Name",
  //       "Gender",
  //       "State",
  //       "Phone",
  //       "Date_of_Birth",
  //       "Language_Prefrence",
  //       "Created_At",
  //       "City",
  //     ];

  //     const sheet1 = [
  //       {
  //         Name: row?.fullname,
  //         Gender: row?.gender,
  //         State: row?.state,
  //         Phone: row?.countryCode + row?.phone,
  //         Date_of_Birth: moment(row?.dob).format("DD-MM-YYYY"),
  //         Language_Prefrence: row?.languagePreference,
  //         Created_At: moment(row.createdAt).format("DD-MM-YYYY"),
  //         City: row?.city,
  //       },
  //     ];

  //     const diarySheet = [];

  //     data?.diary.map((d) => {
  //       d.responses.map((response) => {
  //         // Combine the date and answer into a single string
  //         const answerWithDate = response.answer;

  //         // Check if a row already exists for this question
  //       let existingRow = diarySheet.find(item => item.Question === response.question);
  //       if (!existingRow) {
  //         // If no existing row, create a new one
  //         existingRow = {
  //           Category: d.category,
  //           Question: response.question,
  //           // Scores: response.scores,
  //           // Created_At: moment(d.createdAt).format("DD-MM-YYYY"),
  //         };
  //         diarySheet.push(existingRow);
  //       }
  //       // Add answer with date as a new column
  //       existingRow[`Answer(${moment(d.date).format("DD-MM-YYYY")})`] = answerWithDate;
  //     });
  //   });

  //     const headerDiary = [
  //       "Category",
  //       "Question",
  //       // "Scores",
  //       // "Created_At",
  //       // "Date"
  //     ];

  //     // const diarySheet = [];

  //     // data?.diary.map((d) => {
  //     //   d.responses.map((response) => {
  //     //     diarySheet.push({
  //     //       Category: d.category,
  //     //       Question: response.question,
  //     //       "Answer (date)": `${response.answer} - ${moment(d.date).format("DD-MM-YYYY")}`,
  //     //       Scores: response.scores,
  //     //       Created_At: moment(d.createdAt).format("DD-MM-YYYY"),
  //     //       Date:moment(d.date).format("DD-MM-YYYY"),
  //     //     });
  //     //   });
  //     // });

  //     const headerIbs = ["Question", "Answer", "Created_At", "Date"];

  //     const ibsData = [];

  //     data?.ibsAssessmentScore?.map((ibs) => {
  //       ibs?.assessment?.map((ib) => {
  //         ibsData.push({
  //           Question:
  //             ib?.answeredQuestions?.questionDetails.questionDescription,
  //           Answer: ib?.answeredQuestions?.answer.map((a) => a).join(","),
  //           Created_At: moment(ibs.createdAt).format("DD-MM-YYYY"),
  //           Date: moment(ibs.date).format("DD-MM-YYYY"),
  //         });
  //       });
  //     });

  //     const wb = utils.book_new();
  //     const ws1 = utils.json_to_sheet(sheet1, { header: header1 });
  //     utils.book_append_sheet(wb, ws1, "Patient Details");
  //     const ws2 = utils.json_to_sheet(diarySheet, { header: headerDiary });
  //     utils.book_append_sheet(wb, ws2, "Diary");
  //     const ws3 = utils.json_to_sheet(ibsData, { header: headerIbs });
  //     utils.book_append_sheet(wb, ws3, "IBS Assessment");

  //     const fileName = `${row?.fullname.replace(/\s+/g, '_')}_Details.xlsx`;
  //     writeFile(wb, fileName);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   // Implement the edit action for the selected row
  //   // navigate("/stats");
  // };

  const handleDisplay = async (row) => {
    try {
      const data = await get(
        `/api/dashboard/dashUser/getExcelDatailByPatient/${row?._id}`
      );

      const header1 = [
        "Name",
        "Gender",
        "State",
        "Phone",
        "Date_of_Birth",
        "Language_Prefrence",
        "Created_At",
        "City",
      ];

      const sheet1 = [
        {
          Name: row?.fullname,
          Gender: row?.gender,
          State: row?.state,
          Phone: row?.countryCode + row?.phone,
          Date_of_Birth: moment(row?.dob).format("DD-MM-YYYY"),
          Language_Prefrence: row?.languagePreference,
          Created_At: moment(row.createdAt).format("DD-MM-YYYY"),
          City: row?.city,
        },
      ];

      // Create a set to keep track of unique headers for the diary
      const headerDiarySet = new Set();

      // Transform the data to the required format
      const transformedData = {};
      data?.diary?.forEach((item) => {
        const { category, date, responses } = item;
        const formattedDate = moment(date).format("DD-MM-YYYY"); // Format date

        responses.forEach((response) => {
          const { question, answer } = response;
          if (!transformedData[question]) {
            transformedData[question] = {
              Category: category,
              Question: question,
              answers: {},
            };
          }
          transformedData[question].answers[formattedDate] = answer;
          headerDiarySet.add(formattedDate);
        });
      });

      // Sort dates in headerDiarySet
      const sortedDates = Array.from(headerDiarySet).sort((a, b) =>
        moment(b, "DD-MM-YYYY").diff(moment(a, "DD-MM-YYYY"))
      );

      // Construct the final header array
      const headerDiary = [
        "Category",
        "Question",
        ...sortedDates.map((date) => `Answer(${date})`),
      ];

      // Construct the final result array
      const diarySheet = Object.values(transformedData).map((item) => {
        const answers = sortedDates.reduce((acc, date) => {
          const key = `Answer(${date})`;
          acc[key] = item.answers[date] || ""; // Ensure all dates have corresponding answers
          return acc;
        }, {});

        return {
          Category: item.Category,
          Question: item.Question,
          ...answers,
        };
      });

//       const headerIbs = ["Question", "Answer", "Created_At", "Date"];

//       const ibsData = [];

//       data?.ibsAssessmentScore?.map((ibs) => {
//         ibs?.assessment?.map((ib) => {
//           ibsData.push({
//             Question:
//               ib?.answeredQuestions?.questionDetails.questionDescription,
//             Answer: ib?.answeredQuestions?.answer.map((a) => a).join(","),
//             Created_At: moment(ibs.createdAt).format("DD-MM-YYYY"),
//             Date: moment(ibs.date).format("DD-MM-YYYY"),
//           });
//         });
//       });
//       console.log("data", data);
// // Add a row for Remarks below all other data
// const remarksRow = {
//   Question: "Remarks", 
//   Answer: data?.ibsAssessmentScore?.[0]?.remarks || "", 
//   Created_At: "", 
//   Date: "", 
// };

// // Add the remarks row to the ibsData array
// ibsData.push(remarksRow);

    // Generic function to process assessments
    const processAssessment = (assessmentData, type) => {
      const header = ["Question", "Answer", "Created_At", "Date"];
      const rows = [];

      assessmentData?.forEach((assessment) => {
        assessment?.assessment?.forEach((item) => {
          rows.push({
            Question:
              item?.answeredQuestions?.questionDetails.questionDescription,
            Answer: item?.answeredQuestions?.answer.map((a) => a).join(","),
            Created_At: moment(assessment.createdAt).format("DD-MM-YYYY"),
            Date: moment(assessment.date).format("DD-MM-YYYY"),
          });
        });

        // Add a remarks row
        rows.push({
          Question: "Remarks",
          Answer: assessment?.remarks || "",
          Created_At: "",
          Date: "",
        });
      });

      return { header, rows, sheetName: `${type} Assessment` };
    };

    // Process IBS Assessment
    const ibsAssessment = processAssessment(
      data?.ibsAssessmentScore,
      "IBS"
    );

      const wb = utils.book_new();
      const ws1 = utils.json_to_sheet(sheet1, { header: header1 });
      utils.book_append_sheet(wb, ws1, "Patient Details");
      const ws2 = utils.json_to_sheet(diarySheet, { header: headerDiary });
      utils.book_append_sheet(wb, ws2, "Diary");
      // const ws3 = utils.json_to_sheet(ibsData, { header: headerIbs });
      // utils.book_append_sheet(wb, ws3, "IBS Assessment");
      const ws3 = utils.json_to_sheet(ibsAssessment.rows, {
        header: ibsAssessment.header,
      });
      utils.book_append_sheet(wb, ws3, ibsAssessment.sheetName);
      const fileName = `${row?.fullname.replace(/\s+/g, "_")}_Details.xlsx`;
      writeFile(wb, fileName);
    } catch (error) {
      console.error(error);
    }

    // Implement the edit action for the selected row
    // navigate("/stats");
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Patient's Detail</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by full name or phone number "}
                debounceTime={1000}
              />
            </div>

            {/* <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add patient
            </Button> */}
          </div>
          <CustomTable
            data={users}
            columns={userTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            handleDisplay={handleDisplay}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      {/* common modal for patient and doctor */}
      <PatientModal
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={userFormFields}
        header={editModal ? "Edit Patient" : "Add Patient"}
        initialData={editData}
        isEditing={editModal}
      />
    </>
  );
};

export default Users;
