import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, IconButton, Typography, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { get, post, postFiles } from "../../config/axios";

const AddVideoModal = ({ open, onClose, onSuccess, categoryId }) => {    
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    benefits: "",
    caloriesBurn: "",
    thumbnailFile: null,
    videoFile: null,
    coverImageFile: null,
  });
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async () => {
    try {
      const benefitsArray = formData.benefits.split(",").map((item) => item.trim());
      const payload = { ...formData, benefits: benefitsArray };
  
      // Upload Thumbnail
      if (formData.thumbnailFile) {
        const form = new FormData();
        form.append("file", formData.thumbnailFile);
        const thumbnailResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.thumbnail = thumbnailResponse.data.url;
      }
  
      // Upload Video
      if (formData.videoFile) {
        const form = new FormData();
        form.append("file", formData.videoFile);
        const videoResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.videoUrl = videoResponse.data.url;
      }
  
      // Upload Cover Image
      if (formData.coverImageFile) {
        const form = new FormData();
        form.append("file", formData.coverImageFile);
        const coverImageResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.coverImage = coverImageResponse.data.url;
      }
  
      // Submit Data
      const response = await post("/api/dashboard/yogaFeatures/addYogaVideo", payload);
      if (response?.response === "Success") {
        try {
            const { video } = response.data;
            
            const addToCategory = await post("/api/dashboard/yogaFeatures/addVideoToCategory", {
              videoId: video?._id,
              categoryId
            });
            //clear data
            setFormData({
              title: "",
              description: "",
              benefits: "",
              caloriesBurn: "",
              thumbnailFile: null,
              videoFile: null,
              coverImageFile: null,
            });
          onSuccess();
          onClose();
        } catch (err) {
            console.error("Error adding video in addToCategory:", err);
        }
      }
    } catch (error) {
      console.error("Error adding video:", error);
    }
  };
  

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "800px",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          style={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" mb={2}>
          Add New Yoga Video
        </Typography>
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          fullWidth
          label="Benefits (comma-separated)"
          name="benefits"
          value={formData.benefits}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Calories Burn"
          name="caloriesBurn"
          value={formData.caloriesBurn}
          onChange={handleChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Thumbnail
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="thumbnailFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Video
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="videoFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Cover Image
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="coverImageFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Add Video
        </Button>
      </Box>
    </Modal>
  );
};

export default AddVideoModal;
