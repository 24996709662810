/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, patch, postFiles } from "../../config/axios";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { doctorTableColumns, yogamoduleTableColumns, YogsFormFields } from "../../constants/userPage";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import { DoctorFormFields } from "../../constants/userPage";
import CloseIcon from "@mui/icons-material/Close";
import YogaModal from "./YogaModal";
import moment from "moment";
import VideoModal from "./Videomodal";
import { useNavigate } from "react-router-dom";
import VideoModule from "./VideoModule";

const YogaModule = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);
  const [viewModal, setViewModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewData, setViewData] = useState({});
  const [videoData, setVideoData] = useState([]);
  const [category, setCategory] = useState({
    categoryName: 'Video Details',
    categoryId: ''
  });
  const [editModal, setEditModal] = useState(false);
  const [eyeOpen, setEyeOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();
  const fetchUsers = async (searchValue) => {
    setLoading(true);
    await get(
      `/api/dashboard/yogaFeatures/getYogaCategory?page=${page}&limit=${10}&search=${searchValue}`
    )
      .then((res) => {
        setUsers(
          res?.data.map((item) => ({
            ...item,
            action: { edit: true, delete: true },
          }))
        );
        setLoading(false);
        setPageCount(res?.totalPage);
        setTotalData(res?.totalData);
        setPerPage(res?.perPage);
        setCurrentPage(res?.currentPage);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };  

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    // Implement the edit action for the selected row
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedUser = await patch(
        `/api/dashboard/dashUser/deleteUser/${row._id}`
      );
      setMessage(deletedUser?.message);
      toastMessage(deletedUser?.message, "success");
    } catch (error) {
      console.log("error", error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
      {
        active: active,
      }
    );
    setLoading(false);
    const username = response.data.fullname;
    const message = active
      ? `${username} has been successfully activated.`
      : `${username} has been successfully deactivated.`;

    setMessage(message);
    toastMessage(message, "success");
  };

  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = async (formData, isEditing, id) => {
    console.log("formdata", formData);
    setLoading(true);
    try {
      if (isEditing) {
        // if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
        // }
        const { ...data } = formData;
        if (formData.thumbnail instanceof File) {
          let form = new FormData();
          form.append("file", formData?.thumbnail);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.thumbnail = res.data.url;
        }
        if (formData.categoryIcon instanceof File) {
          let form = new FormData();
          form.append("file", formData?.categoryIcon);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.categoryIcon = res.data.url;
        }
        let response = await put(
          `/api/dashboard/apputility/updateAppContent?id=${id}`,
          data
        );
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        // Check if the required file fields are empty
        if (!formData.categoryIcon || (formData.categoryIcon && formData.categoryIcon.length === 0)) {
          setLoading(false);
          toastMessage("Attachment is required!", "error");
          return;
        }
        if (!formData.thumbnail || (formData.thumbnail && formData.thumbnail.length === 0)) {
          setLoading(false);
          toastMessage("Thumbnail is required!", "error");
          return;
        // } if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
         }
        let form = new FormData();
        form.append("file", formData?.thumbnail);
        const res = await postFiles("/api/app/user/uploadImage", form);
        let form2 = new FormData();
        form2.append("file", formData?.categoryIcon);
        const res2 = await postFiles("/api/app/user/uploadImage", form2);
        const { ...data } = formData;
        data.thumbnail = res.data.url;
        data.categoryIcon = res2.data.url;
        await post("/api/dashboard/yogaFeatures/createYogaCategory", data);
        setMessage("Successfully added");
        setIsModalOpen(false);
        setEditData({});
        toastMessage("Successfully added", "success");
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };
  

  // const handleDisplay = (row) => {
  //   // Implement the edit action for the selected row
  //   console.log("Display", row);
  //   setViewData(row);
  //   // navigate("/yogamodule/videomodule");
  //   setViewModal(true);
  // };

  const handleDisplay = (row) => {
    setVideoData({
      videos: row.videos || [], 
    });
    setCategory({categoryName: row?.name, categoryId: row?._id})
    setViewModal(true);
  };
  

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Yoga Module Detail</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by title"}
                debounceTime={1000}
              />
            </div>

            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add Catoregory
            </Button>
          </div>
          <CustomTable
            data={users}
            columns={yogamoduleTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDisplay={handleDisplay}
            handleStatus={handleStatus}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      {/* common modal for patient and doctor */}
         <YogaModal 
         isOpen={isModalOpen || editModal}
         onClose={() => closeModal(editModal ? "edit" : "add")}
         onSubmit={handleSubmit}
         fields={YogsFormFields}
         header={editModal ? "Edit Category" : "Add Category"}
         initialData={editData}
         isEditing={editModal}
       />
     
      
      <VideoModal
        viewModal={viewModal}
        setViewModal={setViewModal}
        viewData={viewData}
        videoData={videoData}
        category={category}
        setViewData={setViewData}
        fetchUsers={fetchUsers}
      />

    </>
  );
};

export default YogaModule;
