import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import style from "./CustomTable.module.css";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Pagination, Switch } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VideoThumbnail from "react-video-thumbnail";



import { useState } from "react";
const CustomTable = ({
  data,
  columns,
  pageNo,
  pageLimit,
  handleEdit,
  handleDelete,
  handleActive,
  handleDisplay,
  handlePageChange,
  pageNumber,
  pageCount,
  loading,
  totalData,
  perPage,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleOpenModal = (asset) => {
    setSelectedAsset(asset);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAsset(null);
  };

  const handleDownload = (url) => {
    console.log("url", url);
    // Check if url is an array and take the first element
    if (Array.isArray(url)) {
        url = url[0]; // Take the first URL in the array
    }
    // Proceed only if url is a string
    if (typeof url === "string") {
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop(); // Get filename from URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error("Invalid URL:", url);
    }
};

  const handlePageChangePagination = (event, value) => {
    handlePageChange(value);
  };

  let page = pageNumber ? pageNumber : 1;
  let limit = pageLimit ? pageLimit : 10;
  let count = pageCount ? pageCount : 1;


   // Calculate the range
   const startIndex = (pageNumber - 1) * perPage + 1;
    const endIndex = Math.min(pageNumber * perPage, totalData);

  const getNestedProperty = (obj, path) => {
    const keys = path.split(".");
    let result = obj;

    for (const key of keys) {
      if (result === undefined) {
        return "--";
      } else result = obj ? obj[key] : "--";
    }

    return result;
  };

  const formatGender = (gender) => {
    if (!gender) return "";
    return gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          {/* <CircularProgress /> */}
          <img src={process.env.PUBLIC_URL + "/Loader.gif"} alt="Loading..." />
        </div>
      ) : (
        <>
          <TableContainer component={Paper} className={style.main_div}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      sx={{ fontWeight: "bolder", minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, columnIndex) => (
                      <TableCell key={columnIndex} align={column.align}>
                        {column.id === "city" ? (
                          row?.city?.split(",").map((city, index, arr) => (
                            <div key={index}>
                              {city}
                              {index < arr.length - 1 && ','}
                            </div>
                          ))
                        ) : column.id === "doctors" ? (
                          row.doctors.map((doctor, docIndex) => (
                            <div key={docIndex}>
                              <span key={docIndex}>
                                {doctor.fullname}
                                {docIndex < row.doctors.length - 1 && ", "}
                              </span>
                            </div>
                          ))
                        ) :  column.id === "action" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {row?.action?.edit && (
                                <div
                                  onClick={() => {
                                    // Handle edit action for this row
                                    console.log("+++++++", row);
                                    handleEdit(row);
                                  }}
                                >
                                  <IconButton>
                                    <EditIcon
                                      style={{
                                        fontSize: "24px",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                        color: "green",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              )}
                              {row?.action?.delete && (
                                <div
                                  onClick={() => {
                                    // Handle delete action for this row
                                    handleDelete(row);
                                  }}
                                >
                                  <IconButton>
                                    <DeleteIcon
                                      style={{
                                        fontSize: "24px",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </>
                        ) : column.id === "S.No" ? (
                          ++rowIndex + (page - 1) * limit
                        ) : column.id === "title" ? (
                          row?.title.length > 40 ? (
                            <p>{row?.title.substring(0, 40) + "..."}</p>
                          ) : (
                            <p>{row?.title}</p>
                          )
                        ) : column.id === "category.title" ? (
                          row?.title.length > 40 ? (
                            <p>{row?.categoryTitle.substring(0, 40) + "..."}</p>
                          ) : (
                            <p>{row?.categoryTitle}</p>
                          )
                        ) : column.id === "subCategory.title" ? (
                          row?.title.length > 40 ? (
                            <p>
                              {row?.subCategoryTitle.substring(0, 40) + "..."}
                            </p>
                          ) : (
                            <p>{row?.subCategoryTitle}</p>
                          )
                        ) : column.type === "IMAGE" ? (
                          <>
                            {row?.assets?.toString().includes("mp4") ? (
                              <div
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  overflow: "hidden",
                                }}
                              >
                                <VideoThumbnail
                                  videoUrl={row?.assets}
                                  height={100}
                                  width={150}
                                />
                              </div>
                            ) : (
                              <>
                                {row?.assets?.toString().includes("pdf") ? (
                                  <img
                                    src="/assets/pdf.svg"
                                    alt="banner"
                                    style={{ width: "150px", height: "100px" }}
                                  />
                                ) : (
                                  <img
                                    src={getNestedProperty(row, column.id)}
                                    alt="banner"
                                    style={{ width: "150px", height: "100px" }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : column.type === "thumbnail" ? (
                          row.thumbnail ? (
                            <img
                              src={row.thumbnail}
                              alt="thumbnail"
                              style={{ width: "150px", height: "100px", cursor: "pointer" }}
                              onClick={() => handleOpenModal(row.thumbnail)}
                            />
                          ) : (
                            <p>--</p>
                          )
                        ) : column.type === "categoryIcon" ? (
                          row.categoryIcon ? (
                            <img
                              src={row.categoryIcon}
                              alt="categoryIcon"
                              style={{ width: "150px", height: "100px", cursor: "pointer" }}
                              onClick={() => handleOpenModal(row.categoryIcon)}
                            />
                          ) : (
                            <p>--</p>
                          )
                        ): column.type === "VIDEO" ? (
                          <>
                            {row?.assets?.toString().includes("pdf") ? (
                              <img
                                src="/assets/pdf.svg"
                                alt="banner"
                                style={{ width: "150px", height: "100px", cursor: "pointer" }}
                                onClick={() => handleOpenModal(row?.assets)}
                              />
                            ) : row?.assets?.toString().includes("mp4") || row?.videoUrl ? (
                              <div
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  overflow: "hidden",
                                  cursor: "pointer"
                                }}
                                onClick={() => handleOpenModal(row?.assets) || handleOpenModal(row?.videoUrl)}
                              >
                                <VideoThumbnail
                                  videoUrl={row?.assets || row?.videoUrl}
                                  height={100}
                                  width={150}
                                />
                              </div>
                            ) : (
                              <img
                                src={row?.assets}
                                alt="thumbnail"
                                style={{ width: "150px", height: "100px", cursor: "pointer" }}
                                onClick={() => handleOpenModal(row?.assets)}
                              />
                            )}
                          </>
                        ) : column.label === "Active" ? (
                          <Switch
                            checked={row[column.id]}
                            onChange={(event) => {
                              const newActiveValue = event.target.checked;
                              row[column.id] = newActiveValue;
                              handleActive(row?._id, newActiveValue, "active");
                            }}
                          />
                        ) : column.label === "Published" ? (
                          <Switch
                            checked={row[column.id]}
                            disabled={true}
                            onChange={(event) => {
                              const newActiveValue = event.target.checked;
                              handleActive(
                                row?._id,
                                newActiveValue,
                                "published"
                              );
                            }}
                          />
                        ) : column.label === "Attachment" ? (
                          <Link to={row.document}>
                            <IconButton>
                              <DownloadIcon
                                onClick={() => {
                                  handleDisplay(row);
                                }}
                                style={{
                                  fontSize: "24px",
                                  borderRadius: "3px",
                                  cursor: "pointer",
                                  color: "#111",
                                }}
                              />
                            </IconButton>
                          </Link>
                        ):  column.id === "ibsAssessment.fileLocation" && row?.ibsAssessment?.fileLocation ? (
                          <Link to={row?.ibsAssessment?.fileLocation}>
                            <IconButton>
                              <DownloadIcon
                                onClick={() => {
                                  handleDisplay(row);
                                }}
                                style={{
                                  fontSize: "24px",
                                  borderRadius: "3px",
                                  cursor: "pointer",
                                  color: "#111",
                                }}
                              />
                            </IconButton>
                          </Link>
                        ) : column.id === "details" ? (
                          <div
                            onClick={() => {
                              handleDisplay(row);
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                fontSize: "24px",
                                borderRadius: "3px",
                                cursor: "pointer",
                                color: "green",
                              }}
                            />
                          </div>
                        ) : column.id === "videos" ? (
                          <div
                            onClick={() => {
                              handleDisplay(row);
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                fontSize: "24px",
                                borderRadius: "3px",
                                cursor: "pointer",
                                color: "green",
                              }}
                            />
                          </div>
                        ) : column.label === "Vendor Email" ? (
                          row?.vendorId?.email
                        ) : column.id === "socialMediaLink" ? (
                          row?.socialMediaLink === true ? (
                            "YES"
                          ) : (
                            "NO"
                          )
                        ) : column.id === "ticketSystem" ? (
                          row?.ticketSystem === true ? (
                            "YES"
                          ) : (
                            "NO"
                          )
                        ) : column.id === "datetime" ? (
                          `${row?.startDate} - ${row?.startTime}`
                        ) : column.id === "enddatetime" ? (
                          `${row?.endDate} - ${row?.endTime}`
                        ) : column.id === "createdAt" ? (
                          moment.utc(row?.createdAt).format("DD-MM-YYYY")
                        ) : column.id === "startDate" ? (
                          moment.utc(row?.startDate).format("DD-MM-YYYY")
                        ) : column.id === "endDate" ? (
                          moment.utc(row?.endDate).format("DD-MM-YYYY")
                        ) : column.id === "dob" ? (
                          moment.utc(row?.dob).format("DD-MM-YYYY")
                        ) : column.label === "Current Subscription" ? (
                          row?.activeSubscription ? (
                            row?.activeSubscription?.subscriptionTypeId?.name
                          ) : (
                            "InActive"
                          )
                        ) : column.label === "Subscription Expiry" ? (
                          row?.activeSubscription ? (
                            moment
                              .utc(row?.activeSubscription?.expiryDate)
                              .format("YYYY-MM-DD")
                          ) : (
                            "-"
                          )
                        ) : column.id === "vendorName" ? (
                          row?.vendorId?.fullName
                        ) : column.id === "addonName" ? (
                          row?.addon?.name
                        ) : column.id === "gender" ? ( // Transform gender to proper case
                          formatGender(row[column.id])
                        ) : (
                          getNestedProperty(row, column.id)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid 
            container 
            pt={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Grid 
              item 
              xs={12} 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {startIndex ? <Pagination
                count={count}
                page={page}
                onChange={handlePageChangePagination}
              />: ""}
            </Grid>
            <div style={{ 
              position: 'absolute',
              right: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              paddingRight: '16px'
            }}>
              {startIndex ? <p>Showing {startIndex}-{endIndex} of {totalData} records</p>: ''}
            </div>
          </Grid>
          <Dialog 
  open={openModal} 
  onClose={handleCloseModal} 
  maxWidth="lg" // Increase modal width
  fullWidth={true} // Make it take full width of maxWidth
>
  <DialogContent style={{ height: "600px" }}> {/* Increase modal height */}
    {selectedAsset ? (
      selectedAsset.toString().includes(".pdf") ? (
        <div style={{ position: "relative", height: "100%" }}>
          <CircularProgress style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <iframe
            onLoad={(e) => e.target.previousSibling.remove()} // Remove loader after PDF loads
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(selectedAsset[0])}&embedded=true`}
            title="PDF Preview"
            style={{ width: "100%", height: "100%", border: "none" }}
          />
        </div>
      ) : selectedAsset.toString().includes(".mp4") ? (
        <video controls style={{ width: "100%", height: "100%" }}>
          <source src={selectedAsset} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={selectedAsset}
          alt="Preview"
          style={{ width: "100%", height: "auto" }}
        />
      )
    ) : (
      <CircularProgress style={{ margin: "auto", display: "block" }} />
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseModal} color="secondary">
      Close
    </Button>
    <Button
      onClick={() => handleDownload(selectedAsset)}
      color="primary"
      variant="contained"
    >
      Download
    </Button>
  </DialogActions>
</Dialog>

        </>
      )}
    </>
  );
};

export default CustomTable;
