import React, { useState } from "react";
import { Modal, Box, TextField, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postFiles, put } from "../../config/axios";

const EditVideoModal = ({ open, onClose, videoData, onSuccess }) => {
  const [formData, setFormData] = useState({
    title: videoData?.title || "",
    description: videoData?.description || "",
    benefits: videoData?.benefits?.join(", ") || "",
    caloriesBurn: videoData?.caloriesBurn || "",
    thumbnailFile: null,
    videoFile: null,
    coverImageFile: null,
  });

  console.log('check the videoData in EditVideoModal::::', videoData);
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async () => {
    try {
      const payload = { ...formData };
      payload.benefits = formData.benefits.split(",").map((item) => item.trim());

      if (formData.thumbnailFile) {
        const form = new FormData();
        form.append("file", formData.thumbnailFile);
        const thumbnailResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.thumbnail = thumbnailResponse.data.url;
      }

      if (formData.videoFile) {
        const form = new FormData();
        form.append("file", formData.videoFile);
        const videoResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.videoUrl = videoResponse.data.url;
      }

      if (formData.coverImageFile) {
        const form = new FormData();
        form.append("file", formData.coverImageFile);
        const coverImageResponse = await postFiles("/api/app/user/uploadImage", form);
        payload.coverImage = coverImageResponse.data.url;
      }

      const res = await put(`/api/dashboard/yogaFeatures/updateYogaVideo/${videoData?._id}`, payload);
      console.log('::::::::check the res:::::::', res);
      
      onSuccess();
    } catch (error) {
      console.error("Error updating video:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "800px",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          style={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" mb={2}>
          Edit Video
        </Typography>
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          fullWidth
          label="Benefits (comma-separated)"
          name="benefits"
          value={formData.benefits}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Calories Burn"
          name="caloriesBurn"
          value={formData.caloriesBurn}
          onChange={handleChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Thumbnail
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="thumbnailFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Video
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="videoFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Typography variant="subtitle1" mt={2} mb={-1}>
          Upload Cover Image
        </Typography>
        <TextField
          fullWidth
          type="file"
          name="coverImageFile"
          onChange={handleFileChange}
          margin="normal"
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Update Video
        </Button>
      </Box>
    </Modal>
  );
};

export default EditVideoModal;
