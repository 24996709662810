/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import {
  Box,
  Modal,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, postFiles, patch } from "../../config/axios";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import { gutTableColumns } from "../../constants/gutPage";
import styles from "./Gut.module.css";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [blogContentFormFields, setBlogContentFormFields] = useState([
    { name: "title", label: "Title", type: "text", required: true },
    {
      name: "category",
      label: "Category",
      type: "category",
      isMultiSelect: false,
      options: [],
      required: true,
    },
    {
      name: "subCategory",
      label: "SubCategory",
      type: "subCategory",
      isMultiSelect: false,
      options: [],
      required: true,
    },
    {
      name: "languages",
      label: "Language",
      type: "option",
      options: ["ENGLISH", "HINDI"],
      required: true,
    },
    {
      name: "assets",
      label: "Attachment",
      type: "file",
      required: false,
    },
    {
      name: "thumbnail",
      label: "Thumbnail",
      type: "file",
      required: false,
    },
  ]);

  const fetchUsers = async (searchValue) => {
    try {
      setLoading(true);
      const res = await get(
        `/api/dashboard/apputility/getAppContent?page=${page}&limit=${10}&search=${searchValue}&type=GUT_VIDEOS`
      );
      const response = await get(
        `api/dashboard/apputility/getCategory?type=GUT_VIDEOS&totalData=true`
      );
      setBlogContentFormFields((prev) =>
        prev.map((field) => {
          if (field.name === "category") {
            return { ...field, options: response?.data };
          }
          return field;
        })
      );
      setUsers(
        res?.data.map((item) => ({
          ...item,
          action: { edit: true, delete: true },
          categoryTitle: item.category ? item.category?.title : "",
          subCategoryTitle: item.subCategory ? item.subCategory?.title : "",
        }))
      );
      fetchSubCategories();
      setLoading(false);
      setPageCount(res?.totalPage);
      setTotalData(res?.totalData);
      setPerPage(res?.perPage);
      setCurrentPage(res?.currentPage);
    } catch (err) {
      console.error("Error:", err);
      setLoading(true);
    }
  };

  const fetchSubCategories = async () => {
    const res = await get(
      `/api/dashboard/apputility/getSubCategory?category=${selectedCategory}`
    );
    setBlogContentFormFields((prev) =>
      prev.map((field) => {
        if (field.name === "subCategory") {
          return { ...field, options: res?.data };
        }
        return field;
      })
    );
  };
  useEffect(() => {
    fetchSubCategories();
  }, [selectedCategory]);

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    // Implement the edit action for the selected row
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedDietContent = await patch(
        `/api/dashboard/apputility/deleteAppContent/${row._id}`
      );
      setMessage(deletedDietContent?.message);
      toastMessage(deletedDietContent?.message, "success");
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/apputility/updateAppContent?id=${id}`,
      {
        active: active,
      }
    );
    setLoading(false);
    const title = response.data.title;
    const message = active
      ? `${title} has been successfully activated.`
      : `${title} has been successfully deactivated.`;
    setMessage(message);
    toastMessage(message, "success");
  };

  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleDisplay = (row) => {
    // Implement the edit action for the selected row
    console.log("Display", row);
    setViewData(row);
    setViewModal(true);
  };

  const handleSubmit = async (formData, isEditing, id) => {
    console.log("formdata", formData);
    setLoading(true);
    try {
      if (isEditing) {
        // if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
        // }
        const { ...data } = formData;
        if (formData.assets instanceof File) {
          let form = new FormData();
          form.append("file", formData?.assets);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.assets = res.data.url;
        }
        if (formData.thumbnail instanceof File) {
          let form = new FormData();
          form.append("file", formData?.thumbnail);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.thumbnail = res.data.url;
        }
        let response = await put(
          `/api/dashboard/apputility/updateAppContent?id=${id}`,
          data
        );
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        // Check if the required file fields are empty
        if (!formData.assets || (formData.assets && formData.assets.length === 0)) {
          setLoading(false);
          toastMessage("Attachment is required!", "error");
          return;
        }
        if (!formData.thumbnail || (formData.thumbnail && formData.thumbnail.length === 0)) {
          setLoading(false);
          toastMessage("Thumbnail is required!", "error");
          return;
        // } if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
         }
        let form = new FormData();
        form.append("file", formData?.assets);
        const res = await postFiles("/api/app/user/uploadImage", form);
        let form2 = new FormData();
        form2.append("file", formData?.thumbnail);
        const res2 = await postFiles("/api/app/user/uploadImage", form2);
        const { ...data } = formData;
        data.assets = res.data.url;
        data.thumbnail = res2.data.url;
        await post("/api/dashboard/apputility/addAppContent", data);
        setMessage("Successfully added");
        setIsModalOpen(false);
        setEditData({});
        toastMessage("Successfully added", "success");
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Gut Videos</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by title"}
                debounceTime={1000}
              />
            </div>

            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add gut video
            </Button>
          </div>
          <CustomTable
            data={users}
            columns={gutTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            handleDisplay={handleDisplay}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      <FormModal
        accept=".pdf,video/*,image/*"
        setSelectedCategory={setSelectedCategory}
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={blogContentFormFields}
        header={editModal ? "Edit Gut Video" : "Add Gut Video"}
        initialData={editData}
        isEditing={editModal}
      />
       <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
          setViewData({});
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={styles.view_modal}>
        <IconButton
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => {
              setViewModal(false);
              setViewData({});
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ p: 1 }}
            style={{ textAlign: "center" }}
          >
            Details
          </Typography>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <table className={styles.view_table}>
              <thead className={styles.table_head}>
                <tr>
                  <th>Type</th>
                  <th>Details</th>
                </tr>
              </thead>

              <tbody className={styles.table_body}>
                <tr style={{ border: "1px solid black" }}>
                  <td>Title</td>
                  <td>{viewData?.title ? viewData?.title : "-"}</td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Description</td>
                  <td>
                    <div className={styles.description}>
                      {viewData?.description ? viewData?.description : "-"}
                    </div>
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Image</td>
                  <td>
                    {viewData?.assets ? (
                      <div className={styles.image_container}>
                        {viewData?.assets.toString().includes("mp4") ? (
                          <div
                            style={{
                              width: "150px",
                              height: "100px",
                              overflow: "hidden",
                            }}
                          >
                            <video
                              src={viewData?.assets}
                              controls
                              style={{ width: "100%" }}
                            />
                          </div>
                        ) : (
                          <img
                            src={viewData?.assets}
                            alt={`Event`}
                            className={styles.image}
                          />
                        )}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>thumbnail</td>
                  <td>
                    <div className={styles.image_container}>
                      {viewData?.thumbnail ? (
                        <img
                          src={viewData?.thumbnail}
                          alt="thumbnail"
                          className={styles.thumbnail}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Category</td>
                  <td>{viewData?.category?.title}</td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>SubCategory</td>
                  <td>{viewData?.subCategory?.title}</td>
                </tr>
                {/* <tr style={{ border: "1px solid black" }}>
                  <td>Author Name</td>
                  <td>{viewData?.authorName}</td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Read Time</td>
                  <td>{viewData?.readTime}</td>
                </tr> */}
                <tr style={{ border: "1px solid black" }}>
                  <td>Publishing Date</td>
                  <td>
                    {moment.utc(viewData?.createdAt).format("DD-MM-YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              variant="contained"
              style={{ marginTop: "20px" }}
              onClick={() => {
                setViewModal(false);
                setViewData({});
              }}
            >
              close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Users;
